import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import UserService from "../../services/UserService";
import StyledDialogTitle from "../DialogTitle";
import StyledButton from "../Button";
import { createAsset } from "../../pages/train-requester/edcSlice";

export default function CreateAssetModal({
  open,
  handleClose,
  jobId,
  selectedFile,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assetId, setAssetId] = useState("");
  const [assetName, setAssetName] = useState("");

  useEffect(() => {
    setAssetId(assetName.replaceAll(" ", "-").toLowerCase());
  }, [assetName]);

  const handleChangeName = (event) => {
    setAssetName(event.target.value);
  };

  const handleChangeID = (event) => {
    setAssetId(event.target.value);
  };

  const handleSubmit = () => {
    if (assetName == "" || assetId == "") {
      alert("Please fill in all fields");
      return;
    }

    const payload = {
      assetId,
      assetName,
      jobId,
      selectedFile,
    }

    dispatch(createAsset(payload)).then(() => {
      handleClose();
      navigate("/assets");
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <StyledDialogTitle onClose={handleClose}>
        Create New Asset
      </StyledDialogTitle>
      <DialogContent dividers>
        <TextField
          label="Asset Name"
          name="name"
          value={assetName}
          onChange={handleChangeName}
          fullWidth
          required
        />
        <TextField
          label="Asset ID"
          name="assetId"
          value={assetId}
          onChange={handleChangeID}
          fullWidth
          required
          sx={{ mt: 2 }}
          FormHelperTextProps={{
            component: "div",
          }}
          helperText={
            <Box mt={1}>
              <Typography variant="inherit">
                <b>Domain:</b> {process.env.REACT_APP_CS_API_ENDPOINT}
              </Typography>
              <Typography variant="inherit">
                <b>API Endpoint:</b> /api/jobresult/{jobId}/download
              </Typography>
              <Typography variant="inherit">
                <b>Query Params:</b> path={selectedFile}
              </Typography>
            </Box>
          }
        />
      </DialogContent>
      <DialogActions>
        <StyledButton variant="contained" onClick={handleSubmit}>
          Create
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
