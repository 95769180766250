import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/AxiosService";

const initialState = {
  assets: [],
  policies: [],
  contracts: [],
};

export const fetchAssets = createAsyncThunk("edc/fetchAssets", async () => {
  try {
    const response = await axios.get("/edc/assets");
    return response.data.assets;
  } catch (error) {
    alert(error.response.data);
  }
});

export const fetchPolicies = createAsyncThunk("edc/fetchPolicies", async () => {
  try {
    const response = await axios.get("/edc/policies");
    return response.data.policies;
  } catch (error) {
    alert(error.response.data);
  }
});

export const fetchContracts = createAsyncThunk(
  "edc/fetchContracts",
  async () => {
    try {
      const response = await axios.get("/edc/contractdefinitions");
      return response.data.contractDefinitions;
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// CREATE Asset
export const createAsset = createAsyncThunk(
  "edc/createAsset",
  async (payload, { dispatch }) => {
    try {
      await axios.post("/edc/assets", payload);
      dispatch(fetchAssets());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// CREATE Policy
export const createPolicy = createAsyncThunk(
  "edc/createPolicy",
  async (payload, { dispatch }) => {
    try {
      await axios.post("/edc/policies", payload);
      dispatch(fetchPolicies());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// CREATE Contract
export const createContract = createAsyncThunk(
  "edc/createContract",
  async (payload, { dispatch }) => {
    try {
      await axios.post("/edc/contractdefinitions", payload);
      dispatch(fetchContracts());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// DELETE Asset
export const deleteAsset = createAsyncThunk(
  "edc/deleteAsset",
  async (payload, { dispatch }) => {
    try {
      await axios.delete(`/edc/assets/${payload}`);
      dispatch(fetchAssets());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// DELETE Policy
export const deletePolicy = createAsyncThunk(
  "edc/deletePolicy",
  async (payload, { dispatch }) => {
    try {
      await axios.delete(`/edc/policies/${payload}`);
      dispatch(fetchPolicies());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

// DELETE Contract
export const deleteContract = createAsyncThunk(
  "edc/deleteContract",
  async (payload, { dispatch }) => {
    try {
      await axios.delete(`/edc/contractdefinitions/${payload}`);
      dispatch(fetchContracts());
    } catch (error) {
      alert(error.response.data);
    }
  }
);

const edcSlice = createSlice({
  name: "edc",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      .addCase(fetchPolicies.fulfilled, (state, action) => {
        state.policies = action.payload;
      })
      .addCase(fetchContracts.fulfilled, (state, action) => {
        state.contracts = action.payload;
      });
  },
});

export default edcSlice.reducer;
