import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIcon from "@mui/icons-material/Storage";

import { fetchAssets, deleteAsset } from "../../train-requester/edcSlice";

export default function Assets() {
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.edc.assets);

  useEffect(() => {
    dispatch(fetchAssets());
  }, []);

  const handleDeleteAsset = (assetId) => {
    if (window.confirm(`Are you sure you want to delete asset ${assetId}?`)) {
      dispatch(deleteAsset(assetId));
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" fontWeight="bold" my={3}>
        Assets
      </Typography>
      <Grid container spacing={2}>
        {assets?.map((asset) => (
          <Grid item xs={12} sm={6} md={4} key={asset.assetId}>
            <Paper sx={{ p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Stack direction="row" alignItems="center">
                  <StorageIcon fontSize="large" />
                  <Stack ml={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {asset.title}
                    </Typography>
                    <Typography variant="body2">
                      {asset.creatorOrganizationName}
                    </Typography>
                  </Stack>
                </Stack>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteAsset(asset.assetId)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <Typography>{asset.description}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
