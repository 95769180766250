import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import ContractIcon from "@mui/icons-material/Gavel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/ContentCopy";

import { fetchContracts, deleteContract } from "../../train-requester/edcSlice";
import ContractDefinitionModal from "../../../components/contract-definition-modal";

export default function Contracts() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const contracts = useSelector((state) => state.edc.contracts);

  useEffect(() => {
    dispatch(fetchContracts());
  }, [dispatch]);

  const handleDeleteContract = (contractID) => {
    if (
      window.confirm(`Are you sure you want to delete contract ${contractID}?`)
    ) {
      dispatch(deleteContract(contractID));
    }
  };

  const copyToClipboard = (text) => (_) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack my={3} direction="row" alignItems="center" spacing={5}>
          <Typography variant="h4" fontWeight="bold">
            Contract Definitions
          </Typography>
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <AddIcon fontSize="large" />
          </IconButton>
        </Stack>
        <TextField
          label="EDC Endpoint"
          value={process.env.REACT_APP_EDC_DSP_CALLBACK_ADDRESS}
          sx={{ minWidth: 400 }}
          onClick={copyToClipboard(
            process.env.REACT_APP_EDC_DSP_CALLBACK_ADDRESS
          )}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Copy to clipboard">
                <IconButton>
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Stack>
      <Grid container spacing={2}>
        {contracts?.map((contract) => (
          <Grid item xs={12} sm={6} md={4} key={contract.contractDefinitionId}>
            <Paper sx={{ p: 2, height: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Stack direction="row" alignItems="center">
                  <ContractIcon fontSize="large" />
                  <Stack ml={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {contract.contractDefinitionId}
                    </Typography>
                    <Typography variant="body2">Contract Definition</Typography>
                  </Stack>
                </Stack>
                <IconButton
                  color="error"
                  onClick={() =>
                    handleDeleteContract(contract.contractDefinitionId)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                access policy
              </Typography>
              <Typography mb={2}>{contract.accessPolicyId}</Typography>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                contract policy
              </Typography>
              <Typography mb={2}>{contract.contractPolicyId}</Typography>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                assets
              </Typography>
              <Typography>
                {contract.assetSelector[0].operandRight.valueList[0]}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ContractDefinitionModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
}
