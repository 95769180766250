import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import StyledDialogTitle from "../DialogTitle";
import StyledButton from "../Button";
import {
  fetchAssets,
  fetchPolicies,
  createContract,
} from "../../pages/train-requester/edcSlice";

export default function ContractDefinitionModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const { assets, policies } = useSelector((state) => state.edc);
  const [formdata, setData] = useState({
    cid: "",
    accessPolicy: "",
    contractPolicy: "",
    assets: [],
  });

  useEffect(() => {
    if (open) {
      // mounted
      dispatch(fetchAssets());
      dispatch(fetchPolicies());

      setData({
        cid: "",
        accessPolicy: "",
        contractPolicy: "",
        assets: [],
      });
    }
  }, [open, dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (
      formdata.cid === "" ||
      formdata.accessPolicy === "" ||
      formdata.contractPolicy === "" ||
      formdata.assets.length === 0
    ) {
      alert("Please fill in all fields");
      return;
    }

    const payload = {
      contractDefinitionId: formdata.cid,
      accessPolicyId: formdata.accessPolicy,
      contractPolicyId: formdata.contractPolicy,
      assetSelector: [
        {
          operandLeft: "https://w3id.org/edc/v0.0.1/ns/id",
          operator: "IN",
          operandRight: {
            type: "VALUE_LIST",
            valueList: formdata.assets,
          },
        },
      ],
    };

    dispatch(createContract(payload)).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <StyledDialogTitle onClose={handleClose}>
        Create New Contract Definition
      </StyledDialogTitle>
      <DialogContent dividers>
        <TextField
          label="ID"
          name="cid"
          value={formdata.cid}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          select
          label="Access Policy"
          name="accessPolicy"
          value={formdata.accessPolicy}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mt: 2 }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {policies?.map((policy) => (
            <MenuItem
              key={`access-policy-${policy.policyDefinitionId}`}
              value={policy.policyDefinitionId}
            >
              {policy.policyDefinitionId}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Contract Policy"
          name="contractPolicy"
          value={formdata.contractPolicy}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mt: 2 }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {policies?.map((policy) => (
            <MenuItem
              key={`contract-policy-${policy.policyDefinitionId}`}
              value={policy.policyDefinitionId}
            >
              {policy.policyDefinitionId}
            </MenuItem>
          ))}
        </TextField>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="contract-assets">Assets</InputLabel>
          <Select
            labelId="contract-assets"
            id="contract-assets-checkbox"
            name="assets"
            multiple
            value={formdata.assets}
            onChange={handleChange}
            input={<OutlinedInput label="Assets" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {assets?.map((asset) => (
              <MenuItem key={asset.assetId} value={asset.assetId}>
                <Checkbox
                  checked={formdata.assets.indexOf(asset.assetId) > -1}
                />
                <ListItemText primary={asset.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton variant="contained" onClick={handleSubmit}>
          Create
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
