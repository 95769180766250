import { configureStore } from "@reduxjs/toolkit";

import trainsReducer from "../pages/train-requester/trainsSlice";
import aggregationLogReducer from "../pages/train-requester/aggregationLogSlice";
import edcReducer from "../pages/train-requester/edcSlice";

export default configureStore({
  reducer: {
    trains: trainsReducer,
    aggregationLog: aggregationLogReducer,
    edc: edcReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: ["trains/downloadResultItem/fulfilled"],
      },
    }),
});
