import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import RequesterTable from "./components/Table";

import Assets from "../EDC/Assets";
import Policies from "../EDC/Policies";
import Contracts from "../EDC/Contracts";

const TrainRequester = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<RequesterTable />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/contract-definitions" element={<Contracts />} />
      </Routes>
    </Router>
  );
};

export default TrainRequester;
