import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import PolicyIcon from "@mui/icons-material/Policy";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { fetchPolicies, deletePolicy } from "../../train-requester/edcSlice";
import PolicyModal from "../../../components/policy-modal";

const expressionMap = {
  EQ: "=",
  GEQ: "≥",
  GT: ">",
  LEQ: "≤",
  LT: "<",
  NEQ: "!=",
};

export default function Policies() {
  const dispatch = useDispatch();
  const policies = useSelector((state) => state.edc.policies);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPolicies());
  }, []);

  const handleDeletePolicy = (policyId) => {
    if (window.confirm(`Are you sure you want to delete policy ${policyId}?`)) {
      dispatch(deletePolicy(policyId));
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack my={3} direction="row" alignItems="center" spacing={5}>
        <Typography variant="h4" fontWeight="bold">
          Policies
        </Typography>
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <AddIcon fontSize="large" />
        </IconButton>
      </Stack>
      <Grid container spacing={2}>
        {policies?.map((policy) => (
          <Grid item xs={12} sm={6} md={4} key={policy.policyDefinitionId}>
            <Paper sx={{ p: 2, height: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Stack direction="row" alignItems="center">
                  <PolicyIcon fontSize="large" />
                  <Stack ml={2}>
                    <Typography variant="h6" fontWeight="bold">
                      {policy.policyDefinitionId}
                    </Typography>
                    <Typography variant="body2">Policy</Typography>
                  </Stack>
                </Stack>
                <IconButton
                  color="error"
                  onClick={() => handleDeletePolicy(policy.policyDefinitionId)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <Typography variant="caption" sx={{ textTransform: "uppercase" }}>
                constraints
              </Typography>
              {policy.policy.constraints.map((constraint) => (
                <Typography key={constraint.left}>
                  {`${constraint.left} ${expressionMap[constraint.operator]} ${
                    constraint.right.value
                  }`}
                </Typography>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <PolicyModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
}
