import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import StyledDialogTitle from "../DialogTitle";
import StyledButton from "../Button";
import { createPolicy } from "../../pages/train-requester/edcSlice";

const policyPatterns = {
  TIME_RESTRICTED: "Time-Period-Restricted",
  CONNECTOR_RESTRICTED: "Connector-Restricted-Usage",
};

const policyPayloads = {
  [policyPatterns.TIME_RESTRICTED]: ({ policyId, startDate, endDate }) => ({
    policyDefinitionId: policyId,
    policy: {
      constraints: [
        {
          left: "POLICY_EVALUATION_TIME",
          operator: "GEQ",
          right: {
            type: "STRING",
            value: startDate.toISOString(),
          },
        },
        {
          left: "POLICY_EVALUATION_TIME",
          operator: "LT",
          right: {
            type: "STRING",
            value: endDate.toISOString(),
          },
        },
      ],
    },
  }),
  [policyPatterns.CONNECTOR_RESTRICTED]: ({ policyId, connectorId }) => ({
    policyDefinitionId: policyId,
    policy: {
      constraints: [
        {
          left: "REFERRING_CONNECTOR",
          operator: "EQ",
          right: {
            type: "STRING",
            value: connectorId,
          },
        },
      ],
    },
  }),
};

export default function PolicyModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formdata, setData] = useState({
    policyId: "",
    connectorId: "",
    policyType: policyPatterns.TIME_RESTRICTED,
  });

  useEffect(() => {
    if (open) {
      setData({
        policyId: "",
        connectorId: "",
        policyType: policyPatterns.TIME_RESTRICTED,
      });

      setStartDate("");
      setEndDate("");
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (
      formdata.policyId === "" ||
      (formdata.policyType === policyPatterns.CONNECTOR_RESTRICTED &&
        formdata.connectorId === "") ||
      (formdata.policyType === policyPatterns.TIME_RESTRICTED &&
        (startDate === "" || endDate === ""))
    ) {
      alert("One of the fields is missing!");
    } else {
      if (endDate && endDate.isBefore(startDate)) {
        alert("End Date cannot be set before the Start Date");
      }

      const payload = policyPayloads[formdata.policyType]({
        ...formdata,
        startDate,
        endDate,
      });

      dispatch(createPolicy(payload)).then(() => {
        handleClose();
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <StyledDialogTitle onClose={handleClose}>
        Create New Policy
      </StyledDialogTitle>
      <DialogContent dividers>
        <TextField
          label="ID"
          name="policyId"
          value={formdata.policyId}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          select
          label="Type"
          name="policyType"
          value={formdata.policyType}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mt: 2 }}
        >
          {Object.values(policyPatterns)?.map((policy) => (
            <MenuItem key={`policy-pattern-${policy}`} value={policy}>
              {policy}
            </MenuItem>
          ))}
        </TextField>
        {formdata.policyType === policyPatterns.CONNECTOR_RESTRICTED ? (
          <TextField
            label="Connector ID"
            name="connectorId"
            value={formdata.connectorId}
            onChange={handleChange}
            fullWidth
            required
            sx={{ mt: 2 }}
            placeholder="https://other-connector.com"
          />
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <DatePicker
              sx={{ mt: 2 }}
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              sx={{ mt: 2 }}
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton variant="contained" onClick={handleSubmit}>
          Create
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
